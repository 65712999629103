import { useState, useEffect } from "react";
import "./uxRoadmap.css";
import Container from "react-bootstrap/Container";

export default function UxRoadmap() {
  const [image, setImage] = useState(
    "https://dojoitsolutions.com/wp-content/uploads/2024/09/mini uxRoadmap.svg"
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/mini-uxRoadmap.svg"
        );
      } else {
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/uxRoadmap.svg"
        );
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // تشغيل الدالة عند تحميل الصفحة لأول مرة

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section className="uxRoadmap">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2>
            Design process with our <span>UXD</span> practices
          </h2>
          <p>
            Our Solution Development Cycle helps turn your great ideas into
            profitable business solutions.
          </p>
          <div className="uiRoadmap">
            <img src={image} alt="" />
          </div>
        </Container>
      </div>
    </section>
  );
}
