import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section1.css";

export default function Section1() {
  const [section1MainTitle, setSectionMainTitle] = useState(false);
  const [section1SubTitle, setSectionSubTitle] = useState(false);
  const [lgView, setLgView] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 0 && window.innerWidth >= 2500) {
        setLgView(true);
      }
      if (window.scrollY >= 60) {
        setSectionMainTitle(true);
      }
      if (window.scrollY >= 270) {
        setSectionSubTitle(true);
      }
    });
  }, []);

  return (
    <section className="section1">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueportfolio.svg"
            alt=""
          />
          <a href="https://athensmovingexperts.com/" target="_blank">
            <img
              className={
                section1SubTitle || lgView ? "mobilelink" : "notMobilelink"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/AME.png"
              alt=""
            />
          </a>
          <div
            className={
              section1MainTitle || lgView ? "mainTitle1" : "notMainTitle1"
            }
          >
            <h2>Athens Moving Experts</h2>
            <p>
              Athens Moving Experts have been utilized by residential and
              commercial customers.
            </p>
          </div>
          <div
            className={
              section1SubTitle || lgView ? "subTitle1" : "notSubTitle1"
            }
          >
            <p>
              Dojo created a web application for AME to streamline their job
              intake, scheduling, and payment processing. This allowed them to
              work at a much  pace and deliver quality work to their customers.
            </p>
            <a href="https://athensmovingexperts.com/">VISIT SITE </a>
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueLine.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
