import "./video.css";
import Container from "react-bootstrap/Container";

export default function Video() {
  return (
    <section className="videoAbout">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <div className="video">
            <video
              controls
              width={"100%"}
              height={"100%"}
              autoPlay
              loop
              muted
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/DOJO-design.mp4"
              type="mp4"
            ></video>
          </div>
        </Container>
      </div>
    </section>
  );
}
