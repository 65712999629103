import { useState } from "react";
import "./testingCards.css";
import Container from "react-bootstrap/Container";
export default function TestingCards() {
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);
  return (
    <section className="testingCards">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2 className="topH2">
            Wondering what’s your <span>QA </span>requirement? know the basics
          </h2>
          <p className="topP">
            Confused about the testing type your app needs? Gather some basic
            info about the key differentiators between testing approaches and
            make informed decisions
          </p>
          <div className="flex">
            <div
              onMouseEnter={() => setIsHovering1(true)}
              onMouseLeave={() => setIsHovering1(false)}
              className="cards"
            >
              {!isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard1-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering1 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard1-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>On-device Localization Testing</h2>
              <p className="p-1">
                After your translation orders are released by us, you can
                include the translations back to your software and forward your
                beta application to our translation reviewers using a testing
                platform. Thereafter, we will provide a comprehensive report
                listing all the discrepancies and issues we found in app testing
                and help you make changes.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="tri01"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector0.svg"
                alt=""
              />
              <img
                className="log"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector1.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering2(true)}
              onMouseLeave={() => setIsHovering2(false)}
              className="cards"
            >
              {!isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard2-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering2 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard2-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Automated app testing</h2>
              <p className="p-1">
                Automated application testing is the best means to ensure that
                new product versions do not hinder functionality or create new
                bugs. Compared to manual testing, automation in application
                quality assurance offers many benefits such as: Lesser time to
                market Ability to test complex business logic Cost Cutting Less
                risk of human error
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering3(true)}
              onMouseLeave={() => setIsHovering3(false)}
              className="cards"
            >
              {!isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard3-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering3 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard3-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Visual application QA</h2>
              <p className="p-1">
                Easily doable, automated test processes for all layout and
                visual items One-command verification of data, layout and other
                elements Multiple platform validation for all web or app visual
                elements Seamless integration of visual and functional testing
                of all UI elements.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card2-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                alt=""
              />
              <img
                className="card2-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                alt=""
              />
              <img
                className="card2-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering4(true)}
              onMouseLeave={() => setIsHovering4(false)}
              className="cards"
            >
              {!isHovering4 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard4-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering4 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard4-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>User acceptance testing of applications</h2>
              <p className="p-1">
                Easily changeable and writable parameters and to-do list for
                users. Tools to capture all user actions in granular detail, and
                capturing all defects. Our tool automatically takes screenshots
                and statistics during the testing process. Auto-generated
                reports that can even create valuable training materials. Data
                coherent in making actionable decisions easy to reach.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card4-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-1.svg"
                alt=""
              />
              <img
                className="card4-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-2.svg"
                alt=""
              />
              <img
                className="card4-3"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-3.svg"
                alt=""
              />
            </div>
            <div
              onMouseEnter={() => setIsHovering5(true)}
              onMouseLeave={() => setIsHovering5(false)}
              className="cards"
            >
              {!isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard5-icon1.svg"
                    alt=""
                  />
                </div>
              )}
              {isHovering5 && (
                <div className="topIcon">
                  <img
                    src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingCard5-icon2.svg"
                    alt=""
                  />
                </div>
              )}
              <h2>Application QA consulting</h2>
              <p className="p-1">
                Our QA consulting service is absolutely customizable. It lets us
                help you build a quality assurance procedure starting from the
                initial stage and to give you additional support when your QA
                technicians are overwhelmed.Our assessment identifies the good
                practices that may not be getting documented, the user reported
                defects (gone unnoticed in QA) and recommending possible ways
                for QA process improvement. Lastly, we create a framework to
                save costs, lessen defects, enhance productivity and give you a
                substantial advantage.
              </p>
              <p className="p-2">Dojo Solutions</p>
              <img
                className="card5-1"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
                alt=""
              />
              <img
                className="card5-2"
                src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
                alt=""
              />
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
