import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section8.css";

export default function Section8() {
  const [section1MainTitle8, setSectionMainTitle8] = useState(false);
  const [section1SubTitle8, setSectionSubTitle8] = useState(false);
  const [lgView8, setLgView8] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 8140 && window.innerWidth >= 2500) {
        setLgView8(true);
      }
      if (window.scrollY >= 5348 && window.innerWidth < 2500) {
        setSectionMainTitle8(true);
      }
      if (window.scrollY >= 5548 && window.innerWidth < 2500) {
        setSectionSubTitle8(true);
      }
    });
  }, []);

  return (
    <section className="section8">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Rectangle-56.svg"
            alt=""
          />
          <a href="https://www.lepetitamourdesserts.com/" target="_blank">
            <img
              className={
                section1SubTitle8 || lgView8 ? "mobilelink8" : "notMobilelink8"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/leptit.png"
              alt=""
            />
          </a>
          <div
            className={
              section1MainTitle8 || lgView8 ? "mainTitle8" : "notMainTitle8"
            }
          >
            <h2>Lepetit Amour Desserts</h2>
            <p>
              Handcrafted French Macarons LE PETIT AMOUR Based in Raleigh NC.
            </p>
          </div>
          <div
            className={
              section1SubTitle8 || lgView8 ? "subTitle8" : "notSubTitle8"
            }
          >
            <p>
              We helped Lepetit Amour Desserts to expand their business by going
              online with a nice website and order process.
            </p>
            <a href="https://www.lepetitamourdesserts.com/">VISIT SITE </a>
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/redLine.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
