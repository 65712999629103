import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section6.css";
export default function Section6() {
  const [section1MainTitle6, setSectionMainTitle6] = useState(false);
  const [section1SubTitle6, setSectionSubTitle6] = useState(false);
  const [lgView6, setLgView6] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 5650 && window.innerWidth >= 2500) {
        setLgView6(true);
      }
      if (window.scrollY >= 3830 && window.innerWidth < 2500) {
        setSectionMainTitle6(true);
      }
      if (window.scrollY >= 4030 && window.innerWidth < 2500) {
        setSectionSubTitle6(true);
      }
    });
  }, []);
  return (
    <section className="section6">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/yalleow.svg"
            alt=""
          />
          <a href="https://albarakamarketnc.com/" target="_blank">
            <img
              className={
                section1SubTitle6 || lgView6 ? "mobilelink6" : "notMobilelink6"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/albaraka.png"
              alt=""
            />
          </a>
          <div
            className={
              section1MainTitle6 || lgView6 ? "mainTitle6" : "notMainTitle6"
            }
          >
            <h2>Al Baraka</h2>
            <p>
              Al-Baraka Market serves high-end Mediterranean grocery items in
              the metropolitan city, Raleigh.
            </p>
          </div>
          <div
            className={
              section1SubTitle6 || lgView6 ? "subTitle6" : "notSubTitle6"
            }
          >
            <p>
              As the community’s backbone for decades, Al-Baraka Market ensures
              the pure organic and exquisite selection of Mediterranean
              groceries.
            </p>
            <a href="https://albarakamarketnc.com/">VISIT SITE </a>
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/yellowLine.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
