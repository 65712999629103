import { useState, useEffect } from "react";
import "./softwareRoadmap.css";
import Container from "react-bootstrap/Container";

export default function SofewareRoadmap() {
  const [viewClass, setViewClass] = useState(false);
  const [viewClass2, setViewClass2] = useState(false);
  const [viewClassMini, setViewClassMini] = useState(false);
  const [viewClassMini2, setViewClassMini2] = useState(false);
  const [viewClassMini3, setViewClassMini3] = useState(false);
  const [viewClassMini4, setViewClassMini4] = useState(false);
  const [viewClassMini5, setViewClassMini5] = useState(false);
  const [viewClasslg, setViewClasslg] = useState(false);
  const [viewClasslg2, setViewClasslg2] = useState(false);

  const [imageClass, setImageClass] = useState("normal");
  const [image, setImage] = useState(
    "https://dojoitsolutions.com/wp-content/uploads/2024/09/softwareRoadmap.svg"
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setImageClass("mini");
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadsoftmini.svg"
        );
      } else {
        setImageClass("normal");
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadsoft.svg"
        );
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // تشغيل الدالة عند تحميل الصفحة لأول مرة

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 1630 && window.innerWidth <= 700) {
        setViewClassMini(true);
      }
      if (window.scrollY >= 1830 && window.innerWidth <= 700) {
        setViewClassMini2(true);
      }
      if (window.scrollY >= 2035 && window.innerWidth <= 700) {
        setViewClassMini3(true);
      }
      if (window.scrollY >= 2225 && window.innerWidth <= 700) {
        setViewClassMini4(true);
      }
      if (window.scrollY >= 2390 && window.innerWidth <= 700) {
        setViewClassMini5(true);
      }
      if (window.scrollY >= 1200 && window.innerWidth > 700) {
        setViewClass(true);
      }
      if (window.scrollY >= 1400 && window.innerWidth > 700) {
        setViewClass2(true);
      }
      if (window.scrollY >= 120 && window.innerWidth >= 2500) {
        setViewClasslg(true);
      }
      if (window.scrollY >= 400 && window.innerWidth >= 2500) {
        setViewClasslg2(true);
      }
    });
  }, []);
  return (
    <section className="softwareRoadmap">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2>
            Design process with our <span>UXD</span> practices
          </h2>
          <p>
            Our Solution Development Cycle helps turn your great ideas into
            profitable business solutions.
          </p>
          <div className="sfRoadmap">
            {/* <img src={image} alt="" /> */}
            <img
              className={imageClass === "normal" ? "roadSoft" : "miniRoadSoft"}
              src={image}
              alt=""
            />
            <img
              className={
                viewClass || viewClassMini || viewClasslg
                  ? "step1Soft"
                  : "step1Soft notView1Soft"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step1soft.svg"
              alt=""
            />
            <img
              className={
                viewClass || viewClassMini || viewClasslg
                  ? "step1SoftTitle"
                  : "step1SoftTitle notView1SoftTitle"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step1softtitle.svg"
              alt=""
            />
            <img
              className={
                viewClass2 || viewClassMini2 || viewClasslg2
                  ? "step2Soft"
                  : "step2Soft notView2Soft"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step2soft.svg"
              alt=""
            />
            <img
              className={
                viewClass2 || viewClassMini2 || viewClasslg2
                  ? "step2SoftTitle"
                  : "step2SoftTitle notView2SoftTitle"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step2softtitle.svg"
              alt=""
            />
            <img
              className={
                viewClass || viewClassMini3 || viewClasslg
                  ? "step3Soft"
                  : "step3Soft notView3Soft"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step3soft.svg"
              alt=""
            />
            <img
              className={
                viewClass || viewClassMini3 || viewClasslg
                  ? "step3SoftTitle"
                  : "step3SoftTitle notView3SoftTitle"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step3softtitle.svg"
              alt=""
            />
            <img
              className={
                viewClass2 || viewClassMini4 || viewClasslg2
                  ? "step4Soft"
                  : "step4Soft notView4Soft"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step4soft.svg"
              alt=""
            />
            <img
              className={
                viewClass2 || viewClassMini4 || viewClasslg2
                  ? "step4SoftTitle"
                  : "step4SoftTitle notView4SoftTitle"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step4softtilte.svg"
              alt=""
            />
            <img
              className={
                viewClass || viewClassMini5 || viewClasslg
                  ? "step5Soft"
                  : "step5Soft notView5Soft"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step5soft.svg"
              alt=""
            />
            <img
              className={
                viewClass || viewClassMini5 || viewClasslg
                  ? "step5SoftTitle"
                  : "step5SoftTitle notView5SoftTitle"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step5softtitle.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
