import HeroPortfolio from "../components/heroPortfolio/HeroPortfolio";
import Nav from "../components/navbar/Nav";
import Section1 from "../components/section1/Section1";
import Section2 from "../components/section2/Section2";
import Section3 from "../components/section3/Section3";
import Section4 from "../components/section4/Section4";
import Section5 from "../components/section5/section5";
import Section6 from "../components/section6/Section6";
import Section7 from "../components/section7/Section7";
import Section8 from "../components/section8/Section8";
import Section9 from "../components/section9/Section9";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
export default function Portfolio() {
  const [showScrollBTN, setshowScrollBTN] = useState(false);
  const [clickShowScrollBTN, setClickShowScrollBTN] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setshowScrollBTN(true);
      } else {
        setshowScrollBTN(false);
      }
    });
  }, []);
  const myWepRef = useRef(null);
  useEffect(() => {
    myWepRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);
  const myWepRef2 = useRef(null);
  useEffect(() => {
    myWepRef2.current?.scrollIntoView({ behavior: "smooth" });
  }, [clickShowScrollBTN]);
  return (
    <div ref={myWepRef2}>
      <div>
        <Nav />
        <HeroPortfolio />
        <div className="divider2">
          <div className="line"></div>
          <span></span>
        </div>
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <div className="divider3">
          <div className="line"></div>
          <span></span>
        </div>
        <Contact />
        <div className="divider4">
          <div className="line"></div>
          <span></span>
        </div>
        <Footer />
        <Link
          onClick={() => {
            if (clickShowScrollBTN) {
              setClickShowScrollBTN(false);
            } else {
              setClickShowScrollBTN(true);
            }
          }}
          style={{ opacity: showScrollBTN ? 1 : 0, transition: "0.7s" }}
        >
          <button className="icon-chevron-upp">
            <img
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/topp.svg"
              alt=""
            />
          </button>
        </Link>
      </div>
    </div>
  );
}
