import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section2.css";

export default function Section2() {
  const [section1MainTitle2, setSectionMainTitle2] = useState(false);
  const [section1SubTitle2, setSectionSubTitle2] = useState(false);
  const [lgView2, setLgView2] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 350 && window.innerWidth >= 2500) {
        setLgView2(true);
      }
      if (window.scrollY >= 690) {
        setSectionMainTitle2(true);
      }
      if (window.scrollY >= 1050) {
        setSectionSubTitle2(true);
      }
    });
  }, []);

  return (
    <section className="section2">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <a href="https://www.checkuphoops.com/" target="_blank">
            <img
              className={
                section1SubTitle2 || lgView2 ? "mobilelink2" : "notMobilelink2"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/checkup-hoops.png"
              alt=""
            />
          </a>
          <div
            className={
              section1MainTitle2 || lgView2 ? "mainTitle2" : "notMainTitle2"
            }
          >
            <h2>Check Up Hoops</h2>
            <p>High-quality pickup basketball everyday of the week.</p>
            <img
              className="red"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/redportfolio.svg"
              alt=""
            />
          </div>
          <div
            className={
              section1SubTitle2 || lgView2 ? "subTitle2" : "notSubTitle2"
            }
          >
            <p>
              Dojo created an eye-catching design for the check up hoops web
              application. This app can schedule basketball games and many other
              features.
            </p>
            <a href="https://www.checkuphoops.com/">VISIT SITE </a>
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/redLine.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
