import { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import "./mainAbout.css";
function Counter({ start, end, speed }) {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < end) {
        setCount(count + 1);
      } else {
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  });

  return (
    <h1>
      {count}
      <sup>+</sup>
    </h1>
  );
}
export default function MainAbout() {
  return (
    <section className="mainAbout">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2>Meet our Team</h2>
          <p>
            Welcome to the heartbeat of Dojo. Our team is a diverse assembly of
            passionate individuals dedicated to driving innovation and
            delivering excellence.
          </p>
        </Container>
        <div className="photos">
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/sammy-1.svg"
            alt=""
          />
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/shah-2.svg"
            alt=""
          />
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/ayaz-2.svg"
            alt=""
          />
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/khan-2.svg"
            alt=""
          />
        </div>
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <div className="bar">
            <div className="d-flex start">
              <h1>
                <Counter start={0} end={20} speed={40} />
              </h1>
              <p>clients served</p>
            </div>
            <img
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Line-1.svg"
              alt=""
            />
            <div className="d-flex">
              <h1>
                <Counter start={0} end={100} speed={10} />
              </h1>
              <p>projects completed</p>
            </div>
            <img
              className="line2"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Line-1.svg"
              alt=""
            />
            <div className="d-flex end">
              <h1>
                <Counter start={0} end={3} speed={80} />
              </h1>
              <p>products created</p>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
