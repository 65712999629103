import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section4.css";

export default function Section4() {
  const [section1MainTitle4, setSectionMainTitle4] = useState(false);
  const [section1SubTitle4, setSectionSubTitle4] = useState(false);
  const [lgView4, setLgView4] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 3200 && window.innerWidth >= 2500) {
        setLgView4(true);
      }
      if (window.scrollY >= 2400 && window.innerWidth < 2500) {
        setSectionMainTitle4(true);
      }
      if (window.scrollY >= 2600 && window.innerWidth < 2500) {
        setSectionSubTitle4(true);
      }
    });
  }, []);

  return (
    <section className="section4">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <a href="https://exploreerbil.com/" target="_blank">
            <img
              className={
                section1SubTitle4 || lgView4 ? "mobilelink4" : "notMobilelink4"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/explore-erbil.png"
              alt=""
            />
          </a>
          <div
            className={
              section1MainTitle4 || lgView4 ? "mainTitle4" : "notMainTitle4"
            }
          >
            <h2>Explore Erbil</h2>
            <p>
              A website for a client based in Iraq working in tourism industry.
            </p>
            <img
              className="red"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueH.svg"
              alt=""
            />
          </div>
          <div
            className={
              section1SubTitle4 || lgView4 ? "subTitle4" : "notSubTitle4"
            }
          >
            <p>
              Our vision Is to be the premier provider of exceptional travel
              experiences in Erbil, setting the benchmark for excellence in
              service and customer satisfaction.
            </p>
            <a href="https://exploreerbil.com/">VISIT SITE </a>
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueLine.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
