import Container from "react-bootstrap/Container";
import { useState } from "react";
import { Link } from "react-router-dom";

import "./servicesCards.css";
export default function ServicesCards() {
  const [isHovering1, setIsHovering1] = useState(false);
  const [isHovering2, setIsHovering2] = useState(false);
  const [isHovering22, setIsHovering22] = useState(false);
  const [isHovering3, setIsHovering3] = useState(false);
  const [isHovering4, setIsHovering4] = useState(false);
  const [isHovering5, setIsHovering5] = useState(false);
  return (
    <section className="servicesCards">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <div className="flex">
            <div
              onMouseEnter={() => setIsHovering1(true)}
              onMouseLeave={() => setIsHovering1(false)}
              className="cards"
            >
              <Link to={"/wepApp"}>
                {!isHovering1 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard1-icon1.svg"
                      alt=""
                    />
                  </div>
                )}
                {isHovering1 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileCard1-icon2.svg"
                      alt=""
                    />
                  </div>
                )}
                <h2>Web Application Development</h2>
                <p className="p-1">
                  We provide the best Web Application Development using the
                  latest technologies.
                </p>
                <p className="p-2">Dojo Solutions</p>
                <img
                  className="tri01"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector0.svg"
                  alt=""
                />
                <img
                  className="log"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Vector1.svg"
                  alt=""
                />
              </Link>
            </div>
            <div
              onMouseEnter={() => setIsHovering2(true)}
              onMouseLeave={() => setIsHovering2(false)}
              className="cards"
            >
              <Link to={"/mobileApp"}>
                {!isHovering2 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileserv.svg"
                      alt=""
                    />
                  </div>
                )}
                {isHovering2 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/mobileserv2.svg"
                      alt=""
                    />
                  </div>
                )}
                <h2>Mobile Application Development</h2>
                <p className="p-1">
                  We can develop mobile apps using for Android and IOS.
                </p>
                <p className="p-2">Dojo Solutions</p>
                <img
                  className="card2-1"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                  alt=""
                />
                <img
                  className="card2-2"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                  alt=""
                />
                <img
                  className="card2-3"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                  alt=""
                />
              </Link>
            </div>
            <div
              onMouseEnter={() => setIsHovering22(true)}
              onMouseLeave={() => setIsHovering22(false)}
              className="cards"
            >
              <Link to={"/sass"}>
                {!isHovering22 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasserv.svg"
                      alt=""
                    />
                  </div>
                )}
                {isHovering22 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasserv2.svg"
                      alt=""
                    />
                  </div>
                )}
                <h2>SaaS Application Development</h2>
                <p className="p-1">
                  If you need a SaaS app then you are at the right place.
                </p>
                <p className="p-2">Dojo Solutions</p>
                <img
                  className="card2-1"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                  alt=""
                />
                <img
                  className="card2-2"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                  alt=""
                />
                <img
                  className="card2-3"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                  alt=""
                />
              </Link>
            </div>
            <div
              onMouseEnter={() => setIsHovering3(true)}
              onMouseLeave={() => setIsHovering3(false)}
              className="cards"
            >
              <Link to={"/uiux"}>
                {!isHovering3 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/uiserv.svg"
                      alt=""
                    />
                  </div>
                )}
                {isHovering3 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/uiserv2.svg"
                      alt=""
                    />
                  </div>
                )}
                <h2>UI/UX Designing</h2>
                <p className="p-1">
                  Need to design you idea? We are providing the best user
                  experiences for your ideas.
                </p>
                <p className="p-2">Dojo Solutions</p>
                <img
                  className="card2-1"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-1.svg"
                  alt=""
                />
                <img
                  className="card2-2"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-2.svg"
                  alt=""
                />
                <img
                  className="card2-3"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card2-3.svg"
                  alt=""
                />
              </Link>
            </div>
            <div
              onMouseEnter={() => setIsHovering4(true)}
              onMouseLeave={() => setIsHovering4(false)}
              className="cards"
            >
              <Link to={"/software"}>
                {!isHovering4 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard3-icon1.svg"
                      alt=""
                    />
                  </div>
                )}
                {isHovering4 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/saasCard3-icon2.svg"
                      alt=""
                    />
                  </div>
                )}
                <h2>Software Application Maintenance</h2>
                <p className="p-1">
                  We are at your service even after the development for the
                  support and maintenance.
                </p>
                <p className="p-2">Dojo Solutions</p>
                <img
                  className="card4-1"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-1.svg"
                  alt=""
                />
                <img
                  className="card4-2"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-2.svg"
                  alt=""
                />
                <img
                  className="card4-3"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card4-3.svg"
                  alt=""
                />
              </Link>
            </div>
            <div
              onMouseEnter={() => setIsHovering5(true)}
              onMouseLeave={() => setIsHovering5(false)}
              className="cards"
            >
              <Link to={"/testing"}>
                {!isHovering5 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingsev.svg"
                      alt=""
                    />
                  </div>
                )}
                {isHovering5 && (
                  <div className="topIcon">
                    <img
                      src="https://dojoitsolutions.com/wp-content/uploads/2024/09/testingserv2.svg"
                      alt=""
                    />
                  </div>
                )}
                <h2>Software Testing & QA</h2>
                <p className="p-1">
                  We have the best team for the testing of your valued products.
                </p>
                <p className="p-2">Dojo Solutions</p>
                <img
                  className="card5-1"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-1.svg"
                  alt=""
                />
                <img
                  className="card5-2"
                  src="https://dojoitsolutions.com/wp-content/uploads/2024/09/card5-2.svg"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
