import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section3.css";
export default function Section3() {
  const [section1MainTitle3, setSectionMainTitle3] = useState(false);
  const [section1SubTitle3, setSectionSubTitle3] = useState(false);
  const [lgView3, setLgView3] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 1900 && window.innerWidth >= 2500) {
        setLgView3(true);
      }
      if (window.scrollY >= 1540) {
        setSectionMainTitle3(true);
      }
      if (window.scrollY >= 1640) {
        setSectionSubTitle3(true);
      }
    });
  }, []);
  return (
    <section className="section3">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueportfolio.svg"
            alt=""
          />
          <a href="https://thebucyfoundation.org/" target="_blank">
            <img
              className={
                section1SubTitle3 || lgView3 ? "mobilelink3" : "notMobilelink3"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/bucy.png"
              alt=""
            />
          </a>
          <div
            className={
              section1MainTitle3 || lgView3 ? "mainTitle3" : "notMainTitle3"
            }
          >
            <h2>Bucy Foundation</h2>
            <p>
              The Bucy Foundation partners with Raleigh Parks, NCFC Youth, and
              StreetSoccerUSA to help the youth develop necessary life skills.
            </p>
          </div>
          <div
            className={
              section1SubTitle3 || lgView3 ? "subTitle3" : "notSubTitle3"
            }
          >
            <p>
              Dojo created an informational website for the Bucy Foundation in
              order to assist with growth for their non-profit organization. 
            </p>
            <a href="https://thebucyfoundation.org/">VISIT SITE </a>
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/blueLine.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
