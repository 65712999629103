import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css"
import { HashRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import MobileApp from "./pages/MobileApp";
import WebApp from "./pages/WebApp";
import UIUX from "./pages/UIUX";
import Software from "./pages/Software";
import Testing from "./pages/Testing";
import Sass from "./pages/Sass";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import Services from "./pages/Services";
function App() {
  return (

    <HashRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/mobileApp" element={<MobileApp />} />
        <Route element={<WebApp />} path="/wepApp" />
        <Route element={<MobileApp />} path="/mobileApp" />
        <Route element={<UIUX />} path="/uiux" />
        <Route element={<Software />} path="/software" />
        <Route element={<Testing />} path="/testing" />
        <Route element={<Sass />} path="/sass" />
        <Route element={<About />} path="/about" />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Portfolio />} path="/portfolio" />
        <Route element={<Services />} path="/servicesPage" />
      </Routes>
      /</HashRouter>
  );
}
export default App