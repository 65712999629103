import Container from "react-bootstrap/Container";
import "./heroUx.css";
import { HashLink } from "react-router-hash-link";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
export default function HeroUx() {
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const handleCaptchaChange = () => {
    setCaptchaVerified(true);
  };
  const [showaction, setshowaction] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const contactSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("This feild is required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("This feild is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("This feild is required"),
    phoneNumber: Yup.number().min(2, "Too Short!"),
    service: Yup.string(),
    message: Yup.string()
      .min(5, "Too Short!")
      .required("This feild is required"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      service: "",
      message: "",
    },
    validationSchema: contactSchema,
    onSubmit: () => {
      if (!captchaVerified) {
        // عرض رسالة خطأ
        alert("You must prove that you are not a robot");
        return;
      }
      // alert(JSON.stringify(values, null, 2));
      alert("Your request has been received successfully");
    },
  });
  return (
    <section className="heroUx">
      <div className="container2">
        <div className="phone">
          <img
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Graphics.svg"
            alt=""
          />
        </div>
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h1>
            UI /<span> UX </span>DESIGNING
          </h1>
          <p className="p-1">
            With our defined approach and step by step process, we deliver
            beyond what’s expected.
          </p>
          <button
            onClick={() => {
              setshowaction(true);
            }}
          >
            Call To Action
          </button>
          <p className="p-2">
            <HashLink className="linkHero" to={"/home"}>
              Home -
            </HashLink>
            <span> UI / UX DESIGNING</span>
          </p>
          {showaction && (
            <div className="fixedAction">
              <div className="formAction">
                <button
                  onClick={() => {
                    setshowaction(false);
                  }}
                  className="closer"
                >
                  x
                </button>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group className="mb-3" controlId="formGroupfirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      isInvalid={formik.errors.name && formik.touched.firstName}
                      isValid={
                        !formik.errors.firstName && formik.touched.firstName
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.firstName};
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGrouplastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      isInvalid={
                        formik.errors.lastName && formik.touched.lastName
                      }
                      isValid={
                        !formik.errors.lastName && formik.touched.lastName
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.address}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      isInvalid={formik.errors.email && formik.touched.email}
                      isValid={!formik.errors.email && formik.touched.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.email};
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      isInvalid={
                        formik.errors.phoneNumber && formik.touched.phoneNumber
                      }
                      isValid={
                        !formik.errors.phoneNumber && formik.touched.phoneNumber
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.phoneNumber};
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 messageClass"
                    controlId="formGroupService"
                  >
                    <Form.Label>Select Service ?</Form.Label>
                    <Form.Select
                      className="service"
                      // as="select"
                      // type="select"
                      name="service"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.service}
                      isInvalid={
                        formik.touched.service && formik.errors.service
                      }
                      isValid={!formik.errors.service && formik.touched.service}
                    >
                      <option value="1">UI/UX Designing</option>
                      <option value="2">SaaS Application Development</option>
                      <option value="3">Web Application Development</option>
                      <option value="4">Mobile Application Development</option>
                      <option value="5">
                        Software Application Maintenance
                      </option>
                      <option value="6">Software Testing & QA</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.service};
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 messageClass"
                    controlId="formGroupMessage"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      className="message"
                      as="textarea"
                      type="select"
                      name="message"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      isInvalid={
                        formik.touched.message && formik.errors.message
                      }
                      isValid={!formik.errors.message && formik.touched.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.message};
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks good!
                    </Form.Control.Feedback>
                  </Form.Group>
                  <ReCAPTCHA
                    sitekey="6LeWZEMqAAAAAI65SXRFMIBcSQlW0KghLgZVPCj-"
                    onChange={handleCaptchaChange}
                  />
                  <button type="submit" className="submit">
                    Submit
                  </button>
                </Form>
              </div>
            </div>
          )}
        </Container>
      </div>
    </section>
  );
}
