import "./heroPortfolio.css";
import Container from "react-bootstrap/Container";
import { HashLink } from "react-router-hash-link";
export default function HeroPortfolio() {
  return (
    <section className="heroPortfolio">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h1>Our Portfolio</h1>
          <p className="p-2">
            <HashLink className="linkHero" to={"/home"}>
              Home -
            </HashLink>
            <span> Contact us</span>
          </p>
        </Container>
      </div>
    </section>
  );
}
