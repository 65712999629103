import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section5.css";

export default function Section5() {
  const [section1MainTitle5, setSectionMainTitle5] = useState(false);
  const [section1SubTitle5, setSectionSubTitle5] = useState(false);
  const [lgView5, setLgView5] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 4270 && window.innerWidth >= 2500) {
        setLgView5(true);
      }
      if (window.scrollY >= 3014 && window.innerWidth < 2500) {
        setSectionMainTitle5(true);
      }
      if (window.scrollY >= 3214 && window.innerWidth < 2500) {
        setSectionSubTitle5(true);
      }
    });
  }, []);

  return (
    <section className="section5">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <img
            className="blue"
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/pink.svg"
            alt=""
          />
          {/*  <a href="https://exploreerbil.com/" target="_blank">>
            <img
              className={
                section1SubTitle5 || lgView5 ? "mobilelink5" : "notMobilelink5"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/AME.png"
              alt=""
            />
          </a> */}
          <div
            className={
              section1MainTitle5 || lgView5 ? "mainTitle5" : "notMainTitle5"
            }
          >
            <h2>Aesthetic Cleaners</h2>
            <p>Elevate Cleanliness, Embrace Aesthetics.</p>
          </div>
          <div
            className={
              section1SubTitle5 || lgView5 ? "subTitle5" : "notSubTitle5"
            }
          >
            <p>
              Operational in the US to help people to save time, money, and
              stress for their cleaning with aesthetic cleaners.
            </p>
            <a href="https://aestheticleaners.com/">VISIT SITE </a>
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/lineblue.svg"
              alt=""
            />
          </div>
          <div className="dividerPortfolio2">
            <img
              className="dividerPortfolio"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/pinkLine.svg"
              alt=""
            />
          </div>
        </Container>
      </div>
    </section>
  );
}
