import "./testingRoadmap.css";
import Container from "react-bootstrap/Container";

export default function TestingRoadmap() {
  return (
    <section className="testingRoadmap">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2 className="topH2">
            App Testing <span>Edge </span>
          </h2>
          <p className="topP">
            Is acceptable, except when it comes to customer experience and there
            is no room for mistakes that allow a multitude of competitors to
            surpass us.
          </p>
          <div className="list">
            <div className="leftList">
              <p>
                <span></span> We strategize testing with a test-driven approach.
              </p>
              <p>
                <span></span> We use real devices for app testing.
              </p>
              <p>
                <span></span> We test app experience on different data networks.
              </p>
            </div>
            <img
              className="centerLogo"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/listLogo.svg"
              alt=""
            />
            <div className="rightList">
              <p>
                <span></span> We test your app in multiple languages.
              </p>
              <p>
                <span></span> We check fault tolerance, interoperability &
                battery consumption.
              </p>
              <p>
                <span></span> We keep app & user data security our top priority.
              </p>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
}
