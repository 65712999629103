import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import "./mobileRoadmap.css";

export default function MobileRoadmap() {
  const [image, setImage] = useState("image1.jpg");
  const [viewClassMob, setViewClassMob] = useState(false);
  const [viewClassMobMini, setViewClassMobMini] = useState(false);
  const [viewClassMob2Mini, setViewClassMob2Mini] = useState(false);
  const [viewClassMob3Mini, setViewClassMob3Mini] = useState(false);
  const [viewClassMob4Mini, setViewClassMob4Mini] = useState(false);
  const [viewClassMoblg, setViewClassMoblg] = useState(false);
  const [imageClass, setImageClass] = useState("normal");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setImageClass("mini");
        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadminimob.svg"
        );
      } else {
        setImageClass("normal");

        setImage(
          "https://dojoitsolutions.com/wp-content/uploads/2024/09/roadmobile.svg"
        );
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // تشغيل الدالة عند تحميل الصفحة لأول مرة

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 1060 && window.innerWidth > 700) {
        setViewClassMob(true);
      }
      if (window.scrollY >= 1600 && window.innerWidth <= 700) {
        setViewClassMobMini(true);
      }
      if (window.scrollY >= 1800 && window.innerWidth <= 700) {
        setViewClassMob2Mini(true);
      }

      if (window.scrollY >= 2000 && window.innerWidth <= 700) {
        setViewClassMob3Mini(true);
      }
      if (window.scrollY >= 2100 && window.innerWidth <= 700) {
        setViewClassMob4Mini(true);
      }
      if (window.scrollY >= 360 && window.innerWidth >= 2500) {
        setViewClassMoblg(true);
      }
    });
  }, []);

  return (
    <section className="mobileRoadmap">
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <h2>
            Mobile App Development <span>Cycle</span>
          </h2>
          <p>
            Our Solution Development Cycle helps turn your great ideas into
            profitable business solutions.
          </p>
        </Container>
        <div className="mobRoadmap">
          {/* <img src={image} alt="" /> */}
          <img
            className={imageClass === "normal" ? "road" : "miniroad"}
            src={image}
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMobMini || viewClassMoblg
                ? "step1Mob"
                : "step1Mob notViewMob1"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step1mob.svg"
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMobMini || viewClassMoblg
                ? "step1MobTitle"
                : "step1MobTitle notViewMob1Title"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step1mobiletitle.svg"
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMob2Mini || viewClassMoblg
                ? "stepMob2"
                : "stepMob2 notViewMob1Title"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step2mobile.svg"
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMob2Mini || viewClassMoblg
                ? "step2MobTitle"
                : "step2MobTitle notViewMob2Title"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step2mobiletitle.svg"
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMob3Mini || viewClassMoblg
                ? "step3Mob"
                : "step3Mob notViewMob3"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step3mobile.svg"
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMob3Mini || viewClassMoblg
                ? "step3MobTitle"
                : "step3MobTitleMob notViewMob3Title"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step3mobiletitle.svg"
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMob4Mini || viewClassMoblg
                ? "step4Mob"
                : "step4Mob notViewMob4"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step4mob.svg"
            alt=""
          />
          <img
            className={
              viewClassMob || viewClassMob4Mini || viewClassMoblg
                ? "step4MobTitleMob"
                : "step4MobTitleMob notViewMob4Title"
            }
            src="https://dojoitsolutions.com/wp-content/uploads/2024/09/step4mobiletitle.svg"
            alt=""
          />
        </div>
      </div>
    </section>
  );
}
