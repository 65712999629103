import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./section9.css";

export default function Section9() {
  const [section1MainTitle9, setSectionMainTitle9] = useState(false);
  const [section1SubTitle9, setSectionSubTitle9] = useState(false);
  const [lgView9, setLgView9] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0) {
        console.log(window.scrollY);
      }
      if (window.scrollY >= 9400 && window.innerWidth >= 2500) {
        setLgView9(true);
      }
      if (window.scrollY >= 6108 && window.innerWidth < 2500) {
        setSectionMainTitle9(true);
      }
      if (window.scrollY >= 6408 && window.innerWidth < 2500) {
        setSectionSubTitle9(true);
      }
    });
  }, []);

  return (
    <section className="section9">
      <div className="overlay"></div>
      <div className="container2">
        <Container className="mx-auto" style={{ maxWidth: "90%" }}>
          <a href="https://proremarketing.com/" target="_blank">
            <img
              className={
                section1SubTitle9 || lgView9 ? "mobilelink9" : "notMobilelink9"
              }
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/pro-remarketing.png"
              alt=""
            />
          </a>
          <div
            className={
              section1MainTitle9 || lgView9 ? "mainTitle9" : "notMainTitle9"
            }
          >
            <h2>Pro Remarketing</h2>
            <p>
              Vehicle Marketing is the practice of selling used fleet vehicles.
              Vehicle remarketing is based on forecasting trends, knowledge.
            </p>
            <img
              className="red"
              src="https://dojoitsolutions.com/wp-content/uploads/2024/09/Rectangle-526.svg"
              alt=""
            />
          </div>
          <div
            className={
              section1SubTitle9 || lgView9 ? "subTitle9" : "notSubTitle9"
            }
          >
            <p>
              Fleet managers often assume there’s no value left in their
              vehicles and opt to dispose of them, but vehicle remarketing can
              help fleet owners return a fair market value for their used
              vehicles.
            </p>
            <a href="https://proremarketing.com/">VISIT SITE </a>
          </div>
        </Container>
      </div>
    </section>
  );
}
